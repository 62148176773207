import { WORDS } from "../constants/wordlist";
import { VALIDGUESSES } from "../constants/validGuesses";

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  );
};

export const isWinningWord = (word: string) => {
  return GlobalVars.solution === word;
};

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf();
  const now = Date.now();
  const msInDay = 300000;
  //const index = Math.floor((now - epochMs) / msInDay);
  const index = Math.floor(Math.random() * WORDS.length);
  //const index = 1;


  return {
    //solution: WORDS[index].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
    //solution_w: WORDS[index].toUpperCase(),
    solution2: WORDS[index].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
    solution_w2: WORDS[index].toUpperCase(),
    solutionIndex: index,
  };
};

export const { solution2, solution_w2, solutionIndex } = getWordOfDay();

export class GlobalVars {
  public static solution: string = solution2;
  public static solution_w: string = solution_w2;
}

