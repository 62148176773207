import { GameStats } from '../../lib/localStorage';

type Props = {
  gameStats: GameStats;
};

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4">
      <div className="text-3xl font-bold text-gray-900 dark:text-gray-200">{value}</div>
      <div className="text-xs text-gray-900 dark:text-gray-200">{label}</div>
    </div>
  )
};

export const StatBar = ({ gameStats }: Props) => {
  return (
    <div className="flex justify-center my-2">
      <StatItem label="Partidas Jogadas" value={gameStats.totalGames} />
      <StatItem label="Taxa de Acerto" value={`${gameStats.successRate}%`} />
      <StatItem label="Sequência Atual" value={gameStats.currentStreak} />
      <StatItem label="Melhor Sequência" value={gameStats.bestStreak} />
    </div>
  )
};
