import { useState, useEffect } from 'react';
import { CharStatus } from "../../lib/statuses";
import classnames from "classnames";

type Props = {
  value?: string;
  status?: CharStatus;
};

export const Cell = ({ value, status }: Props) => {

	const [cellAnimation, setCellAnimation] = useState('')

	  useEffect(() => {
	    if (value !== undefined) {
	      setCellAnimation('cellAnimation')
	    }
	  }, [value]);
	  
  const classes = classnames(
    "w-12 h-12 border-solid border-2 flex items-center justify-center mx-0.5 text-3xl font-bold rounded",
    {
      "bg-white dark:bg-slate-700 dark:text-gray-100 dark:border-slate-400 border-slate-200": !status,
      "shadowed bg-slate-400 dark:bg-slate-500 text-white dark:border-slate-500 border-slate-400": status === "absent",
      "shadowed bg-green-500 dark:bg-green-600 text-white dark:border-green-600 border-green-500": status === "correct",
      "shadowed bg-yellow-500 dark:bg-yellow-600 text-white dark:border-yellow-600 border-yellow-500": status === "present",
    }
  );

  return (
    <>
      <div className={`${classes} ${cellAnimation}`}>{value}</div>
    </>
  );
};
