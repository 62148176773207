import { getGuessStatuses } from "./statuses";
import { solutionIndex } from "./words";

export const shareStatus = (guesses: string[],sequencia: number, lost: boolean) => {
	var text_result=null;
	var result = false;
	
	if (sequencia > 1){
		text_result= ("Charada ∞ [" +
			solutionIndex +
			"] " +
			`${lost?"X":guesses.length}/6\n\n` +
			generateEmojiGrid(guesses) +
			"\nSequência atual: " + sequencia +
			"\n\n🎮 charada.app"
		);
	}else{
		text_result= ("Charada ∞ [" +
			solutionIndex +
			"] " +
			`${lost?"X":guesses.length}/6\n\n`+
			generateEmojiGrid(guesses) +
			"\n\n🎮 charada.app"
		);

	}
	

  
	try {
		navigator.share({text: text_result,});
		return true

	}catch(err) {
		if (!navigator.clipboard) {
			alert("Navegador não compatível com compartilhamento.");
			return false
		}
			navigator.clipboard.writeText(text_result).then(function() {
			result = true;
			
			
		}, function(err) {
			return false
			
		}
		);

	}
	
	return result

};

export const shareMinhaStatus = (name: string, guesses: string[], lost: boolean, link: string) => {
	var text_result=null;
	var result = false;
	
	text_result= (name + " " +
		`${lost?"X":guesses.length}/6\n\n`+
		generateEmojiGrid(guesses) +
		"\n\n🎮 charada.app/d/" + link
	);
  
	try {
		navigator.share({text: text_result,});
		return true

	}catch(err) {
		if (!navigator.clipboard) {
			alert("Navegador não compatível com compartilhamento.");
			return false
		}
			navigator.clipboard.writeText(text_result).then(function() {
			result = true;
			
			
		}, function(err) {
			return false
			
		}
		);

	}
	
	return result
};

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess);
      return guess
        .split("")
        .map((letter, i) => {
          switch (status[i]) {
            case "correct":
              return "🟩";
            case "present":
              return "🟨";
            default:
              return "⬜";
          }
        })
        .join("");
    })
    .join("\n");
};

export const copyLink = (link: string) => {
    navigator.clipboard.writeText(link);
};

export const shareLink = (name: string, link: string) => {

  var text= ("Te desafio a descobrir " + name + 
             "\n\n🎮 Jogue em " + link + 
             "\n\n via charada.app/desafie");
		
  try {
    navigator.share({text: text,})
  } catch(err) {
    navigator.clipboard.writeText(text);
  }

};
