import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { InformationCircleIcon, ChartBarIcon } from "@heroicons/react/outline";
import { LightBulbIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import { Alert } from "./components/alerts/Alert";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { InfoModal } from "./components/modals/InfoMinhaModal";
import { WinMinhaModal } from "./components/modals/WinMinhaModal";
import { LoseMinhaModal } from "./components/modals/LoseMinhaModal";
import { CreateModal } from "./components/modals/CreateModal";
import { isWordInWordList, isWinningWord, solutionIndex, GlobalVars } from "./lib/words";
import { addStatsForCompletedGame, loadStats } from "./lib/stats";
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setAlertaLocalStorage,
} from "./lib/localStorage";

var solution = '';
var solutionw = '';

type Props = {
  nome: string;
  palavra: string;
  sharelink: string;
};

function Minha({nome, palavra, sharelink}: Props) {
  const [GameName, setGameName] = useState("");
  const [currentGuess, setCurrentGuess] = useState("");
  const [isGameFinished] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const [isWinMinhaModalOpen, setIsWinMinhaModalOpen] = useState(false);
  const [isLoseMinhaModalOpen, setIsLoseMinhaModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false);
  const [isWordNotLengthAlertOpen, setWordNotLengthAlertOpen] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [shareComplete, setShareComplete] = useState(false);
  const [guesses, setGuesses] = useState<string[]>(() => {
  
  	if ((nome === "")||(nome === undefined)){
  	    setGameName("#MinhaCharada");
  	}else{
  	    setGameName(nome);
  	}
  	
	solutionw = palavra;
	solution = solutionw.normalize('NFD').replace(/[^a-záàâãéèêíóôõúçA-ZÁÀÂÃÉÈÊÍÓÔÕÚÇ]/g, '').toUpperCase();
	GlobalVars.solution = solution;
	
    const loaded = loadGameStateFromLocalStorage("MinhaCharadaStat")
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === 5 && !gameWasWon) {
      setIsGameLost(true);
    }
    return loaded.guesses
		
  });

  useEffect(() => {
    saveGameStateToLocalStorage("MinhaCharadaStat",{ guesses, solution, solutionw });
  }, [guesses]);

  useEffect(() => {
    if (isGameWon) {
      setIsWinMinhaModalOpen(true);
    }
  }, [isGameWon]);
  
  useEffect(() => {
    setAlertaLocalStorage();
  }, []);
  
  const toogle = () => {
  
    if (localStorage.theme === 'dark'){
    	localStorage.theme = 'light'
    } else {
    	localStorage.theme = 'dark'    
    }
    
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
		document.documentElement.classList.add('dark')
    } else {
		document.documentElement.classList.remove('dark')
    }
  };

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (isGameWon) {
        setIsWinMinhaModalOpen(true);
    }
    else if (guesses.length === 6 && !isGameWon){
    	  setIsLoseMinhaModalOpen(true);
    }
    else {
        if (currentGuess.length < 5 ) {
            setWordNotLengthAlertOpen(true);
        return setTimeout(() => {
            setWordNotLengthAlertOpen(false);
          }, 4000);
        }
        
        if (!isWordInWordList(currentGuess) && (currentGuess !== GlobalVars.solution)) {
          setIsWordNotFoundAlertOpen(true);
          return setTimeout(() => {
            setIsWordNotFoundAlertOpen(false);
          }, 4000);

    	}
 
    }

    const winningWord = isWinningWord(currentGuess);

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess("");

      if (winningWord) {
        return setIsGameWon(true); 
      }

      if (guesses.length === 5) {
        setIsLoseMinhaModalOpen(true);
      }
    }
  };

  return (
    <div className="bg-slate-100 dark:bg-slate-900 max-h-full flex flex-col h-screen sm:pt-3 lg:pt-6 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <Alert message="Palavra não encontrada" isOpen={isWordNotFoundAlertOpen} />
      <Alert message="Insira uma palavra com 5 letras" isOpen={isWordNotLengthAlertOpen} />
      <Alert
        message={`You lost, the word was: ${GlobalVars.solution_w}`}
        isOpen={isGameLost}
      />
      <Alert
        message="Resultado copiado para área de transferência"
        isOpen={shareComplete}
        variant="success"
      />
      <div className="flex w-80 mx-auto items-center mb-3 pt-2 px-2">
        <div className="flex-none">
        <LightBulbIcon
          className="h-6 w-6 cursor-pointer dark:text-gray-300"
          onClick={toogle}
        />
            
        </div>  
        <div className="flex-grow">
        <h3 className="text-xl grow font-bold text-center font-mono dark:text-slate-200 text-gray-700">{GameName}</h3>
        </div>
        <div className="flex-none">
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:text-gray-300"
          onClick={() => setIsInfoModalOpen(true)}
        />
        </div>
        
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <WinMinhaModal
        isOpen={isWinMinhaModalOpen}
        handleClose={() => setIsWinMinhaModalOpen(false)}
        guesses={guesses}
        nome={GameName}
        sharelink={sharelink}
        handleShare={() => {
          setIsWinMinhaModalOpen(false);
          setShareComplete(true);
          return setTimeout(() => {
            setShareComplete(false);
          }, 4000);
        }}
      />
      <LoseMinhaModal
        isOpen={isLoseMinhaModalOpen}
        handleClose={() => setIsLoseMinhaModalOpen(false)}
        guesses={guesses}
        solution_w={solutionw}
        nome={GameName}
        sharelink={sharelink}
        handleShare={() => {
          setIsLoseMinhaModalOpen(false);
          setShareComplete(true);
          return setTimeout(() => {
            setShareComplete(false);
          }, 4000);
        }}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        nome={GameName}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      
      <p className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs rounded text-indigo-500">
        O termo deste desafio, é de inteira responsabilidade criador do desafio personalizado.
      </p>
      

    </div>
  );
}

export default Minha;
