import { Cell } from "../grid/Cell";

export const HowTo = () => {
  return (
                 
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      Você tem 6 tentativas para adivinhar a palavra. Após cada tentativa, os blocos irão indicar quão próximo seu palpite está da palavra correta. <b>Atenção:</b> acentos serão preenchidos automaticamente.
                    </p>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="P" status="correct" />
                      <Cell value="O" />
                      <Cell value="R" />
                      <Cell value="T" />
                      <Cell value="A" />
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      A letra P está na palavra e na posição correta.
                    </p>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="P" />
                      <Cell value="I" />
                      <Cell value="S" status="present" />
                      <Cell value="T" />
                      <Cell value="A" />
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      A letra S está na palavra, mas não na posição correta.
                    </p>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="D" />
                      <Cell value="I" />
                      <Cell value="C" />
                      <Cell value="A" status="absent" />
                      <Cell value="S" />
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      A letra A não existe em nenhuma posição da palavra.
                    </p>
                  </div>
                  
  );
};
