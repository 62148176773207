import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BanIcon, XIcon, DuplicateIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { MiniGrid } from "../mini-grid/MiniGrid";
import { shareMinhaStatus } from "../../lib/share";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  solution_w: string;
  handleShare: () => void;
  nome: string;
  sharelink: string;
};

export const LoseMinhaModal = ({
  isOpen,
  handleClose,
  guesses,
  solution_w,
  handleShare,
  nome,
  sharelink
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-gray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-top bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:max-w-sm sm:w-full sm:p-6">
              <div>
            		<div className="flex">
                  	<div className="flex-none w-6"></div>
                  	<div className="flex-grow">
                  	 <div className="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-red-100 dark:bg-red-900">
						            <BanIcon
						              className="h-10 w-10 text-red-600 dark:text-red-200"
						              aria-hidden="true"
						            />
						          </div>

                  	</div>
                  
		                <div className="flex-none w-6">
				              <div className="fixed right-4 top-4">
												<XIcon
													className="h-6 w-6 cursor-pointer dark:text-white"
													onClick={() => handleClose()}
												/>
											</div>
		                </div>
                  </div>
              
                
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    Não foi dessa vez!
                  </Dialog.Title>
                  <div className="mt-2">
                    <MiniGrid guesses={guesses} />                   
                    <Dialog.Title
                    as="h3"
                    className="text-lg leading-4 font-medium text-gray-700 dark:text-white"
                  >
                    A palavra era: <b>{solution_w}</b>
                  </Dialog.Title>
                  </div>
                </div>
              </div>
              
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                    if(shareMinhaStatus(nome, guesses, true, sharelink)){
                       handleShare();                    
                    };            
                    
                  }}
                >
                  Compartilhar<DuplicateIcon className="mx-1 h-6 w-6 cursor-pointer" />
                </button>
              </div>
             
              <div className="mt-4 sm:mt-2">
 <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 dark:bg-emerald-700 dark:hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm"
                   onClick={() => {
                    window.location.replace("/");
                  }}

                >
                  Jogar Charada<ExternalLinkIcon className="mx-1 h-6 w-6 cursor-pointer" />
                </button>
                </div>
                
                <p className="text-sm pt-2 text-gray-500 dark:text-gray-200">
                      Crie o seu desafio personalizado {" "}
                      <a
                        href="/desafie"
                        className="underline font-bold select-none"
                        target="_blank"
                      >
                        aqui.
                      </a>{" "}</p>
                
                
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
