import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Cell } from "../grid/Cell";
import { HowTo } from "./HowTo";
import { XIcon } from "@heroicons/react/outline";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-gray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-full"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-top bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="text-justify">
                  <div className="flex text-center">
                  	<div className="flex-none w-6"></div>
                  	<div className="flex-grow">
		                	<Dialog.Title
		                  as="h3"
		                  className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
		                	>
		                  Como Jogar		                 
		                	</Dialog.Title>
                  	</div>
                  
		                <div className="flex-none w-6">
				              <div className="fixed right-4 top-4">
												<XIcon
													className="h-6 w-6 cursor-pointer dark:text-white"
													onClick={() => handleClose()}
												/>
											</div>
		                </div>
                  </div>
                  
                  <HowTo />
                  
                  <p className="p-3"><hr /></p>
                  
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    Sobre
                  </Dialog.Title>
                  <div className="mt-2 text-left">
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      <b>CHARADA</b> é uma versão tropicalizada e infinita baseada no projeto livre{" "}
                      <a
                        href="https://github.com/hannahcode/word-guessing-game"
                        className="underline font-bold select-none"
                        target="_blank"
                        rel="nofollow"
                      >
                        hannahcode
                      </a>.{" "}</p>
                      <p className="text-sm text-gray-500 dark:text-gray-200">
                      Siga-nos no Twitter{" "}
                      <a
                        href="https://twitter.com/palavracharada"
                        className="underline font-bold select-none"
                        target="_blank"
                        rel="nofollow"
                      >
                        @palavracharada
                      </a>{" "}</p>
                      
                      
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
