import { GameStats } from "../../lib/localStorage";
import { Progress } from "./Progress";

type Props = {
  gameStats: GameStats;
};

export const Histogram = ({ gameStats }: Props) => {
  const { totalGames, winDistribution } = gameStats
  
  const maxValue = Math.max(...winDistribution)

  return (
    <div className="columns-1 justify-left m-2 text-sm dark:text-gray-200">
      {winDistribution.map((value, i) => (
        <Progress
          key={i}
          index={(i + 1).toString()}
          size={90 * (value / maxValue)}
          label={String(value)}
        />
      ))}
      <br />
      <hr />
      <br />
      <Progress
          key={6}
          index={"X"}
          size={90 * (gameStats.gamesFailed / totalGames)}
          label={String(gameStats.gamesFailed)}
        />
    </div>
  )
};
