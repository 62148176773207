const gameStateKey = "CharadaState";

type StoredGameState = {
  guesses: string[];
  solution: string;
  solutionw: string;
};

export const saveGameStateToLocalStorage = (gamekey: string, gameState: StoredGameState) => {
  localStorage.setItem(gamekey, JSON.stringify(gameState));
};

export const setAlertaLocalStorage = () =>{
  localStorage.setItem("0-TRAPAÇA", "Jogue limpo! Tô de olho em você espiando o Local Storage 👀");
};


export const loadGameStateFromLocalStorage = (gamekey="") => {
  if(gamekey==="")
  {
	gamekey = gameStateKey;  
  }
  const state = localStorage.getItem(gamekey);

  return state ? (JSON.parse(state) as StoredGameState) : null;
};

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[];
  gamesFailed: number;
  currentStreak: number;
  bestStreak: number;
  totalGames: number;
  successRate: number;
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey);
  return stats ? (JSON.parse(stats) as GameStats) : null;
}
